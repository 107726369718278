import React, { useState, useEffect } from 'react';

import { FormControl, useFormControlContext } from '@mui/base/FormControl';
import { Input, inputClasses } from '@mui/base/Input';
import { styled } from '@mui/system';
import clsx from 'clsx';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { InputBase } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faCheckCircle, faClock, faWarning } from '@fortawesome/free-solid-svg-icons';
import './registration.css';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';


export default function Registration() {
    const [name, setName] = React.useState(null);
    const [email, setEmail] = React.useState(null);
    const [phone, setPhone] = React.useState(null);
    const [address, setAddress] = React.useState(null);
    const [numRooms, setNumRooms] = React.useState(0);
    const [startDate, setStartDate] = React.useState(null);
    const [fromTime, setFromTime] = React.useState(null);
    const [toTime, setToTime] = React.useState(null);

    const [weekly, setWeeklyChecked] = React.useState(false);
    const [biWeekly, setBiWeeklyChecked] = React.useState(false);
    const [monthly, setMonthlyChecked] = React.useState(true);
    const [isVisible, setIsVisible] = useState(true);
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const [cost, setCost] = useState(0);
    const [discount, setDiscount] = useState(0);

    const [loading, setLoading] = useState(false);
    const [loadComplete, setLoadComplete] = useState(false);
    const [validation, setValidation] = useState(false);

    const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    useEffect(() => {
      const handleScroll = () => {
        if(window.innerHeight < 900 || window.innerWidth < 1500) {
            setIsVisible(true); 
            return;
        } 
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  
        if (scrollTop > lastScrollTop && scrollTop > 200) {
          setIsVisible(false); // Hide the header when scrolling down
        } else if(scrollTop < lastScrollTop && scrollTop < 200) {
          setIsVisible(true); // Show the header when scrolling up
        }
  
        setLastScrollTop(scrollTop);
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [lastScrollTop]);

    const handleNameChange = (event) =>{
        setName(event.target.value);
    }

    const handleEmailChange = (event) =>{
        setEmail(event.target.value);
    }

    const handlePhoneChange = (event) =>{
        setPhone(event.target.value);
    }

    const handleAddressChange = (event) =>{
        setAddress(event.target.value);
    }

    const handleStartDateChange = (event) =>{
        setStartDate(event.target.value);
    }

    const handleFromTimeChange = (event) =>{
        setFromTime(event.target.value);
    }

    const handleToTimeChange = (event) =>{
        setToTime(event.target.value);
    }

    const handleRoomCostChange = (event) =>{
      setNumRooms(event.target.value);

      if(weekly){ 
        setCost(event.target.value*50*0.6); 
        setDiscount(40); 
      }
      if(biWeekly){
        setCost(event.target.value*50*0.8); 
        setDiscount(20);
      }

      if(monthly) setCost(event.target.value*50); 
    }

    const handleChangeWeekly = (event) => {
        setWeeklyChecked(event.target.checked);

        setBiWeeklyChecked(false);
        setMonthlyChecked(false);

        setCost(numRooms*50*0.6); 
        setDiscount(40); 
    };

    const handleChangeBiWeekly = (event) => {
        setWeeklyChecked(false);
        setBiWeeklyChecked(event.target.checked);
        setMonthlyChecked(false);

        setCost(numRooms*50*0.8); 
        setDiscount(20);
    };

    const handleChangeMonthly = (event) => {
        setWeeklyChecked(false);
        setBiWeeklyChecked(false);
        setMonthlyChecked(event.target.checked);
        setCost(numRooms*50); 
        setDiscount(0);
    };


    const checkFormFilled= () => {
      let emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      let check = true;
      if(name == null || name === "") check = false;
      if(email == null || email === ""|| !emailPattern.test(email)) check = false;
      if(phone == null || phone === "") check = false;
      if(address == null || address === "") check = false;
      if(numRooms < 1) check = false;
      if(startDate == null || startDate === "") check = false;
      if(fromTime == null || fromTime === "") check = false;
      if( !(weekly || biWeekly || monthly) ) check = false;
      if(cost < 1) check = false;
      return check;
    }
    
    const sendForm = async() =>{
      setLoading(true);
      setValidation(false);

      if(checkFormFilled()){
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: process.env.REACT_APP_HYDRABLEND_NODE_API+'/spartan/register',
          headers: { 
            'authorization-token': process.env.REACT_APP_ACCESS_TOKEN, 
            'Content-Type': 'application/json'
          },
          data : { 
            "name": name,
            "email": email,
            "phone": phone,
            "address": address,
            "numRooms": numRooms,
            "startDate": startDate,
            "fromTime": fromTime,
            "toTime": toTime,
            "frequency": (weekly)? "weekly": (biWeekly)? "bi-weekly": (monthly)? "monthly" : null,
            "cost": cost 
          }
        };

        axios.request(config).then(resp=>{
          setLoadComplete(true);
        }).catch(err =>{
          
        });
      }else{
        await wait(3000);
        setLoading(false);
        setValidation(true);
      }
    }

    return (
        <Card className="registration-form" sx={{ display: isVisible ? 'block' : 'none', background: "linear-gradient(seagreen, beige, beige, beige, seagreen)" }}>
            <Typography sx={{ color: "beige",fontWeight: "bold", marginTop: 2, textAlign: "center"}} gutterBottom variant="h5" component="div">Schedule Your Appointment</Typography>
            <CardContent sx={{ marginLeft: "20px" }}>
                <FormControl style={{ marginBottom: 10 }} defaultValue="" required>
                    <Label sx={{fontWeight: "bold"}}>Name</Label>
                    <StyledInput onChange={handleNameChange.bind(this)} placeholder="Write your name here" />
                    { <HelperText style={{ paddingTop: "2px", color: "indianred"}} /> }
                </FormControl>
                <FormControl style={{ marginBottom: 10 }}  defaultValue="" required>
                    <Label sx={{fontWeight: "bold"}}>Email</Label>
                    <StyledInput onChange={handleEmailChange.bind(this)} type="email" placeholder="Write your email here" />
                    <HelperText style={{ paddingTop: "2px", color: "indianred"}} />
                </FormControl>
                <FormControl style={{ marginBottom: 10 }}  defaultValue="" required>
                    <Label sx={{fontWeight: "bold"}}>Phone</Label>
                    <StyledInput onChange={handlePhoneChange.bind(this)} type="phone" placeholder="Write your phone number here" />
                    <HelperText style={{ paddingTop: "2px", color: "indianred"}} />
                </FormControl>
                <FormControl style={{ marginBottom: 10 }}  defaultValue="" required>
                    <Label sx={{fontWeight: "bold"}}>Address (street/ house or apt unit number)</Label>
                    <StyledInput onChange={handleAddressChange.bind(this)} type="address" placeholder="Enter service address here" />
                    <HelperText style={{ paddingTop: "2px", color: "indianred"}} />
                </FormControl>
                <FormControl style={{ marginBottom: 10 }}  defaultValue="" required>
                    <Label sx={{fontWeight: "bold"}}>Number Of Rooms</Label>
                    <StyledInput value={numRooms} onChange={handleRoomCostChange.bind(this)} type="number" min={0} placeholder="Include living rooms, kitchens, bathrooms" 
                    InputProps={{min: 0, max: 10}}/>
                    <HelperText style={{ paddingTop: "2px", color: "indianred"}} />
                </FormControl>
                <FormControl style={{ marginBottom: 10, width: 150 }}  defaultValue="" required>
                    <Label sx={{fontWeight: "bold"}}>Service Date Start</Label>
                    <StyledInput onChange={handleStartDateChange.bind(this)} style={{width: 150 }} type="date"  />
                    <HelperText style={{ paddingTop: "2px", color: "indianred"}} />
                </FormControl>
                <FormControl style={{ marginBottom: 10 }}  defaultValue="" required>
                    <Label sx={{fontWeight: "bold"}}>Time Availability</Label>
                    <StyledInput onChange={handleFromTimeChange.bind(this)} type="time"  />
                    <HelperText style={{ paddingTop: "2px", color: "indianred"}} />
                </FormControl>
                <FormControl style={{ marginBottom: 10 }}  defaultValue="" required>
                    <Label sx={{fontWeight: "bold"}}>To</Label>
                    <StyledInput onChange={handleToTimeChange.bind(this)} type="time" />
                    <HelperText style={{ paddingTop: "2px", color: "indianred"}} />
                </FormControl>
                <FormControl style={{ marginBottom: 10 }}  defaultValue="" required>
                   <Label sx={{fontWeight: "bold"}}>Frequency of Service</Label>
                    <div>
                        <FormControlLabel
                            control={<Checkbox
                                checked={monthly}
                                onChange={handleChangeMonthly}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />}
                            label="monthly"
                        />
                        <FormControlLabel
                            control={<Checkbox
                                checked={biWeekly}
                                onChange={handleChangeBiWeekly}
                                inputProps={{ 'aria-label': 'controlled' }}
                                
                            />}
                            label="bi-weekly"
                        />
                                                <FormControlLabel
                            control={<Checkbox
                                checked={weekly}
                                onChange={handleChangeWeekly}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />}
                            label="weekly"
                        />
                    </div>               
                    <HelperText style={{ paddingTop: "2px", color: "indianred"}} />
                </FormControl>
                { discount == 20 && !loadComplete&& <p className="promotion" sx={{ textAlign: "center", paddingBottom: "2px", color: "saddlebrown", fontWeight: "bold"}}>* 20% Discount! * Billed Every {(weekly)? "Week!": (biWeekly)? "2 Weeks!": (monthly)? "Month!": ""} </p> }
                { discount == 40 && !loadComplete&& <p className="promotion" sx={{textAlign: "center", paddingBottom: "2px", color: "saddlebrown", fontWeight: "bold"}}>* 40% Discount! * Billed Every {(weekly)? "Week!": (biWeekly)? "2 Weeks!": (monthly)? "Month!": ""} </p> }
                { discount == 0 && !loadComplete&& <p className="promotion" sx={{textAlign: "center", paddingBottom: "2px", color: "saddlebrown", fontWeight: "bold"}}> Billed Every {(weekly)? "Week!": (biWeekly)? "2 Weeks!": (monthly)? "Month!": ""} </p> }
                {  loadComplete && <p className="promotion" sx={{textAlign: "center", paddingBottom: "2px", color: "darkgreen", fontWeight: "bold"}}><FontAwesomeIcon icon={faCheckCircle} style={{marginRight: 10}} />Thank You!</p> }

                { !loading && !loadComplete && <Button id="copy-number-text" variant="contained" style={{width: "100%", fontWeight: "bold", fontSize: 14,  background: "green"}} onClick={sendForm.bind(this)}><FontAwesomeIcon icon={faBook} style={{paddingRight: 10}} />Book Now | (${cost})</Button>}
                { loading && !loadComplete && <Button id="copy-number-text" variant="contained" style={{width: "100%", fontWeight: "bold", fontSize: 14,  background: "darkcyan"}} ><CircularProgress style={{color:"white", width:"25px", height: "25px", marginRight: "10px"}} />Submitting ...</Button>}
                { loading && loadComplete && <Button id="copy-number-text" variant="contained" style={{width: "100%", fontWeight: "bold", fontSize: 14,  background: "darkgoldenrod"}}><FontAwesomeIcon icon={faClock} style={{marginRight: 10}} /> We will be in touch within 24 hours! </Button>}
                { !loading && loadComplete &&<Button id="copy-number-text" variant="contained" style={{width: "100%", fontWeight: "bold", fontSize: 14,  background: "green"}} onClick={sendForm.bind(this) }><FontAwesomeIcon icon={faWarning} style={{paddingRight: 10}} /> Try-Again! | (${cost}) </Button>}
                { validation && <p style={{ paddingTop: "5px", color: "floralWhite", textAlign: "center"}}>Make Sure Form Is Completed!</p>}

                
                
            </CardContent>
        </Card>
    );
}

const StyledInput = styled(Input)(
  ({ theme }) => `

  .${inputClasses.input} {
    width: 320px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      outline: 0;
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    }
  }
`,
);

const Label = styled(({ children, className }) => {
  const formControlContext = useFormControlContext();
  const [dirty, setDirty] = React.useState(false);

  React.useEffect(() => {
    if (formControlContext?.filled) {
      setDirty(true);
    }
  }, [formControlContext]);

  if (formControlContext === undefined) {
    return <p>{children}</p>;
  }

  const { error, required, filled } = formControlContext;
  const showRequiredError = dirty && required && !filled;

  return (
    <p className={clsx(className, error || showRequiredError ? 'invalid' : '')}>
      {children}
      {required ? ' *' : ''}
    </p>
  );
})`
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  margin-bottom: 4px;

  &.invalid {
    color: red;
  }
`;

const HelperText = styled((props) => {
  const formControlContext = useFormControlContext();
  const [dirty, setDirty] = React.useState(false);

  React.useEffect(() => {
    if (formControlContext?.filled) {
      setDirty(true);
    }
  }, [formControlContext]);

  if (formControlContext === undefined) {
    return null;
  }

  const { required, filled } = formControlContext;
  const showRequiredError = dirty && required && !filled;

  return showRequiredError ? <p {...props}>This field is required.</p> : null;
})`
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
`;

const blue = {
  100: '#DAECFF',
  200: '#b6daff',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

